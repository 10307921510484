$(document).foundation();

$(document).ready(function()
{
    // cookie box
    $( '.cookie-button' ).click( function()
    {
        Cookies.set( 'cookie_notice', '1', { expires: 365, path: '/' } );
        $( '.box-cookie' ).slideUp();

        return false;
    });


    if( Cookies.get( 'cookie_notice' ) )
    {
        $( '.box-cookie' ).hide();
    }
    else
    {
        $( '.box-cookie' ).show();
    }


    $('header').headroom({
        "offset": 0,
        "tolerance": 0
    });

    var links = [];

    $(".box-news article figure img").each(function() {
        var $this = $(this);
        var src = $this.attr('src');
        links.push(src);
        $this.attr('gallery-index', links.length - 1);
    });

    $(".box-news article figure img").click(function(event) {
        event = event || window.event;
        var target = event.target || event.srcElement,
            link = target.src ? target.parentNode : target,
            options = {index: link, event: event};
        var gallery = blueimp.Gallery(links, options);
        var gallery_index = parseInt( $(this).attr('gallery-index') );

        if( ( gallery_index + 1 ) == links.length )
        {
            gallery.slide( ( gallery_index - 1 ), 100 );
            gallery.next();
        }
        else
        {
            gallery.slide( gallery_index, 100 );
        }
    });
});